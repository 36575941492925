<template>
  <div class="header_r">
    <div class="title">康旅控股集团品牌群</div>
    <div class="line"></div>
    <div class="img_cont">
      <div class="img_left">
        <div v-for="(item, index) in ingList" :key="index">
          <img :src="item.img" class="imgli" v-if="index != 1"/>
          <img :src="item.img" @click="goGW" class="imgli big" v-else/>
        </div>
      </div>
      <div class="img_right">
        <div v-for="(item, index) in imgList" :key="index" style="width: 12%;">
          <img :src="item.img" class="imglis">
        </div>
        <div class="imglis bigs" style="width: 35%;">
            康旅品质 · 匠心智造
          </div>
            <!-- <div class="imglis imglis_s">
          <img src="@/assets/QQ.png" style="width: 100%;" alt="Description">
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ingList: [
        {
          id: 1,
          img: require("@/assets/Group19.png"),
          //  img: require("@/assets/z19.png"),
        },
        {
          id: 2,
          img: require("@/assets/Group21.png"),
        },
        {
          id: 3,
          img: require("@/assets/Group20.png"),
        }
      ],
      imgList2:[
          {
          id: 9,
          img: require("@/assets/f9.png"),
        },
        {
          id: 10,
          img: require("@/assets/f6.png"),
        },
        {
          id: 11,
          img: require("@/assets/f10.png"),
        },
        {
          id: 12,
          img: require("@/assets/f7.png"),
        },
        {
          id: 13,
          img: require("@/assets/f12.png"),
        }
      ],
      imgList: [
        {
          id: 1,
          img: require("@/assets/f18.png"),
        },
        {
          id: 2,
          img: require("@/assets/f11.png"),
        },
        {
          id: 3,
          img: require("@/assets/f13.png"),
        },
        {
          id: 4,
          img: require("@/assets/f14.png"),
        },
        {
          id: 5,
          img: require("@/assets/f15.png"),
        },
        {
          id: 6,
          img: require("@/assets/f16.png"),
        },
        {
          id: 7,
          img: require("@/assets/f17.png"),
        },
        {
          id: 8,
          img: require("@/assets/f8.png"),
        },
        {
          id: 9,
          img: require("@/assets/f9.png"),
        },
        {
          id: 10,
          img: require("@/assets/f6.png"),
        },
        {
          id: 11,
          img: require("@/assets/f10.png"),
        },
        {
          id: 12,
          img: require("@/assets/f7.png"),
        },
        {
          id: 13,
          img: require("@/assets/f12.png"),
        }
      ]
    };
  },
  methods:{
    goGW(){
      window.open('http://www.kanglvholding.com/','_blank')
    }
  }
};
</script>

<style lang="scss" scoped>
.header_r {
  width: 100%;
  // height: 668px;
  padding: 48px 0;
  background-color: #fff;
}
@media (min-width: 1000px) {
  .img_cont{
  width: calc(100% - 850px);
  }
}
@media (max-width: 1000px) {
  .img_cont{
  width: calc(100% - 580px);
  }
}
.img_cont {
  // width: calc(100% - 580px);
  //  width: calc(100% - 850px);
  // width: 100%;
  margin: 0 auto;
  // display: flex;
  // justify-content: space-between;

  .img_left {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    .imgli {
      width: 98%;
      max-height: 364px;
      margin-bottom: 24px;
      transition: transform 0.3s ease-out;
      position: relative;
      z-index: 2;
      @media (max-width: 1200px) {
        margin-bottom: 13px;
      }
        @media (max-width: 1100px) {
         margin-bottom: 7px;
            //  font-size: 5px;
      }
        @media (max-width: 1000px) {
          margin-bottom: 24px;
      }
      @media (max-width: 992px) {
        margin-bottom: 3px;
      }

      @media (max-width: 768px) {
        margin-bottom: 3px;
      }
    }
    .big{
      width: 98%;
      max-height: 364px;
    }
  }

  .img_right {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 auto;
    // .bigs {
    //   // height: 167px;
    //   padding: 5% 0;
    //   // flex: 1;
    //   text-align: center;
    //   // line-height: 167px;
    //   background-color: #1E937E !important;
    //   border-radius: 6px;
    //   font-size: 20px;
    //   color: #fff;
    //   user-select: none;
    //   transition: transform 0.3s ease-out;
    //   position: relative;
    //   z-index: 2;
    // }
   .bigs {
      padding: 4% 0;
      text-align: center;
      // background-color: #1E937E !important;
      background: rgba(25, 44, 59, 0.85) !important;
      border-radius: 6px;
      // font-size: 20px;
      color: #fff;
      user-select: none;
      transition: transform 0.3s ease-out;
      position: relative;
      z-index: 2;
        @media (min-width: 1500px) {
        font-size: 16px;
      }
        @media (min-width: 1200px) {
        font-size: 14px;
      }
        @media (max-width: 1000px) {
        font-size: 14px;
      }
      // @media (max-width: 992px) {
      //   font-size: 13px;
      // }

      // @media (max-width: 768px) {
      //   font-size: 3px;
      // }
    }
    .imglis {
      width: 100%;
      max-height: 167px;
      margin-bottom: 24px;
      transition: transform 0.3s ease-out;
      position: relative;
      z-index: 2;
      background-color: #F6F7F7;
        @media (max-width: 1200px) {
        margin-bottom: 13px;
        //  font-size: 11px;
      }
        @media (max-width: 1100px) {
         margin-bottom: 7px;
        //  font-size: 5px;
      }
        @media (max-width: 1000px) {
          margin-bottom: 24px;
          //  font-size: 5px;
      }
      @media (max-width: 992px) {
        margin-bottom: 4px;
      }

      @media (max-width: 768px) {
        margin-bottom: 4px;
      }
    }
  }
}

.bigs:hover {
  transform: scale(1.1);
}

.big:hover {
  transform: scale(1.1);
}

.imgli:hover {
  transform: scale(1.1);
}

.imglis:hover {
  transform: scale(1.1);
}

.title {
  font-size: 31px;
  font-weight: 400;
  color: #232323;
  line-height: 38px;
  padding-top: 64px;
}

.line {
  margin: 22px auto 64px;
  width: 100px;
  border: 3px solid #1E937E;
  background: #1e937e;
}
</style>
